<template>
  <div class="container">
    <van-nav-bar class="backBox" title="领取优惠券" right-text="" />
    <!-- 车主扫码领取优惠券 -->
    <div class="section-2">
      <!--<div style="width: 100%;height: 20px;line-height: 20px;text-align: center;font-size: 18px;margin-top: 5px;font-weight: 600">{{park.parkname}}</div>-->
      <div class="type" v-if="Number(para.type) === ruleType.money">
        <div class="price"><span>￥</span>{{ para.value / 100 }}</div>
        <div class="divider"></div>
        <!--        //金额-->
        <div class="info">
          <div>{{ ruleTypeLabel[ruleType.money] }}</div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div class="type" v-if="Number(para.type) === ruleType.moneyActual">
        <div class="price">
          <template v-if="Number(para.all_free) === 1"> 全免 </template>
          <template v-else> <span>￥</span>{{ para.value / 100 }} </template>
        </div>
        <div class="divider"></div>
        <!--        //金额实际减-->
        <div class="info">
          <div>
            {{
              Number(para.all_free) === 1
                ? '全免'
                : ruleTypeLabel[ruleType.moneyActual]
            }}
          </div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div class="type type1" v-if="Number(para.type) === ruleType.time">
        <div class="price">{{ para.value + '分钟' }}</div>
        <div class="divider"></div>
        <div class="info">
          <div>{{ ruleTypeLabel[ruleType.time] }}</div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div class="type type1" v-if="Number(para.type) === ruleType.timeActual">
        <div class="price">
          {{ Number(para.all_free) === 1 ? '全免' : para.value + '分钟' }}
        </div>
        <div class="divider"></div>
        <div class="info">
          <div>
            {{
              Number(para.all_free) === 1
                ? '全免'
                : ruleTypeLabel[ruleType.timeActual]
            }}
          </div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div class="type type2" v-if="Number(para.type) === ruleType.disBreaks">
        <div class="price">{{ para.value / 10 + '折' }}</div>
        <div class="divider"></div>
        <div class="info">
          <div>{{ ruleTypeLabel[ruleType.disBreaks] }}</div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div
        class="type type2"
        v-if="Number(para.type) === ruleType.disBreaksActual"
      >
        <div class="price">{{ para.value / 10 + '折' }}</div>
        <div class="divider"></div>
        <div class="info">
          <div>{{ ruleTypeLabel[ruleType.disBreaksActual] }}</div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <div class="type type3" v-if="Number(para.type) === ruleType.free">
        <div class="price">1张</div>
        <div class="divider"></div>
        <div class="info">
          <div>全免券</div>
          <div>
            {{ para | filterStartEnd }}
          </div>
          <div v-if="para.allow_s_time && para.allow_e_time">
            {{ para | translateTime }}
          </div>
        </div>
      </div>
      <template v-if="!sendSuccess">
        <div class="plate_num_box">
          <div class="plate_num_box_tit" style="text-align: left">
            请输入车牌号
          </div>
          <div class="plate_num_box_input">
            <div
              class="plate_wrap"
              v-for="(item, index) in plateNumber"
              :key="index"
              @click.stop="bindFocus(index)"
            >
              <div
                class="plate_wrap_input"
                v-if="index !== 7"
                :class="[input_index === index ? ' section-dashed' : '']"
              >
                {{ item }}
              </div>
              <!-- 2 -->
              <div
                class="plate_wrap_input new_input"
                v-if="index === 7"
                :class="[input_index === index ? ' section-dashed' : '']"
              >
                <div v-if="newFlag" placeholder="">
                  {{ item }}
                </div>
                <img
                  v-if="!newFlag"
                  src="../../assets/img/indexnewnengyuan@2x.png"
                  alt=""
                />

                <span v-if="!newFlag" class="new_vh">新能源</span>
              </div>
            </div>
          </div>
          <!-- 增加历史记录展示 -->
          <div class="history">
            <!-- 图标 -->
            <span
              class="historyIcon"
              v-show="historyNumList && historyNumList.length > 0"
            >
              <van-icon class="iconfont icon-lishi" />
            </span>
            <div
              class="historyItem"
              v-for="item in historyNumList"
              :key="item.id"
              data-info="item"
              @click="clickHistoryNum(item)"
            >
              {{ item }}
            </div>
          </div>

          <van-button
            @click="receive"
            class="query_btn"
            color="#4797EA"
            type="info"
          >
            立即领取
          </van-button>
          <div class="notVhNumReceiveBox">
            <span class="notVhNumReceiveClass" @click="notVhNumReceive()">
              无牌车领券请点这里
            </span>
            <div class="notVhNumReceiveTip" style="font-size: 14px">
              无牌车领券，请确保该车已扫码入场，不在该停车场内的无牌车，不能领取优惠券。扫码入场领优惠券离场时扫码支付，请使用同一部手机。
            </div>
          </div>
        </div>
        <!-- <div class="btn" @click="receive()">立即领取</div> -->
        <div @click="queryOrder(1)" class="query-onsite-info">查看在场信息</div>
      </template>
      <template v-if="sendSuccess">
        <div class="success"></div>
        <div class="success1">{{ successTips }}</div>
      </template>
    </div>
    <div class="dialog" v-show="para.visible">
      <div class="box">
        <div style="text-align: right">
          <img
            src="../../assets/img/close.svg"
            style="width: 20px; height: 20px"
            @click="para.visible = false"
          />
        </div>
        <div style="font-weight: bold; margin-bottom: 20px">
          请输入优惠券提取密码
        </div>
        <van-password-input
          :value="para.pass"
          :length="4"
          :gutter="para.gutter"
          :mask="false"
          info="密码为 4 位数字"
          :focused="para.showNumboard"
          @focus="para.showNumboard = true"
        />
        <van-number-keyboard
          :show="para.showNumboard"
          @input="onInput"
          @delete="onDelete"
          @blur="para.showNumboard = false"
        />
        <div class="dialogBtn" @click="canSend()">提取优惠券</div>
      </div>
    </div>
    <!-- 键盘 -->
    <car-keyboard
      ref="keyboard"
      :show.sync="showKeyboard"
      @getResult="setNumber"
      :plateNumber.sync="plateNumber"
      :index.sync="input_index"
      @setIndex="setIndex"
    ></car-keyboard>
    <van-popup v-model="inOutInfo.show" position="bottom">
      <!-- <van-cell class="vanCellFirst" title="入场图片" :value="inOutInfo.empty" /> -->
      <!-- <div>
        <van-image
        width="300"
        height="100"
        :src="inOutInfo.inImg"
        />
      </div> -->
      <!-- <van-cell /> -->

      <van-cell title="车牌号码" :value="inOutInfo.plateNumber" />
      <van-cell title="入场时间" :value="inOutInfo.inTime" />
      <van-cell title="停车时长" :value="inOutInfo.parkTime" />
      <van-cell title="停车金额" :value="inOutInfo.totalFee" />
    </van-popup>
  </div>
</template>
<script>
import axios from 'axios'
import * as mqtt from '../../api/mqtt/wsconnect.js'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as util from '../../api/util'
import * as constant from '../../api/constant'
import CarKeyboard from '../../components/CarKeyboard.vue'
// import { Popup, Cell } from 'vant'
import { Dialog } from 'vant'
import { mapState } from 'vuex'

export default {
  components: {
    CarKeyboard
  },
  data () {
    return {
      park: {
        parkname: '枫林车场'
      },
      para: {
        showNumboard: false,
        pass: '',
        sid: '',
        rule_id: '',
        coupon_id: '',
        value: '',
        type: '',
        sTime: '',
        eTime: '',
        secret: '',
        approveFlag: 0, // 活动券-是否需要审核;默认0不需要
        onsiteFlag: 0, // 全部券-默认不开启在场车检查
        projectNumLimit: 0, // 活动券-默认不开启活动券叠加限制
        parkedTime: '',
        fee: 0,
        category: '',
        gutter: 15,
        visible: false,
        name: '',
        permanent: false,
        allow_s_time: '',
        allow_e_time: '',
        e_time_length: '',
        all_free: 0,
        pnulloutvisible: false // 无牌车
      },
      ruleType: {
        moneyActual: 11, // 减免金额
        timeActual: 12, // 减免时间
        disBreaksActual: 13, // 打折减免
        money: 1, // 减免金额
        time: 2, // 减免时间
        disBreaks: 3, // 打折减免
        free: 4 // 全免
      },
      ruleTypeLabel: {
        12: '时长减免券',
        11: '金额减免券',
        13: '折扣减免券',
        4: '单次全免券',
        1: '金额减免券(券面)',
        2: '时长减免券(券面)',
        3: '折扣减免券(券面)'
      },
      sendSuccess: false,
      plate: '',
      successTips: '',
      inOutInfo: {
        show: false,
        plateNumber: '',
        inTime: '',
        parkTime: '',
        totalFee: '',
        empty: '',
        inImg: ''
      },
      showKeyboard: false,
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      newFlag: false,
      index: '',
      qryOnsiteWhenGetCoupon: false,
      historyNumList: [],
      historyFlag: false
    }
  },
  computed: {
    ...mapState({
      queryLogHistory: state => state.queryLogHistory.queryLogHistory
    })
  },
  filters: {
    translateTime (para) {
      if (para.allow_s_time === '00:00:00' && para.allow_e_time === '23:59:59') {
        return ''
      } else {
        return '可用时段范围:' + para.allow_s_time + '-' + para.allow_e_time
      }
    },
    filterStartEnd (row) {
      if (row.category === 1) {
        const tempStr = translatTime()
        if (tempStr) return '有效期:领券后' + translatTime()
        else return ''
      } else {
        let str = ''
        if (row.e_time_length === -1) {
          if (/^1970/.test(row.sTime) && /^2999/.test(row.eTime)) {
            str = ''
          } else {
            str = '可用日期范围:' + row.sTime + '-' + row.eTime
          }
        } else {
          const tempStr = translatTime()
          if (tempStr) {
            str = '有效期:领券后' + tempStr + '\n'
          }
        }
        return str
      }

      function translatTime () {
        const min = Number(row.e_time_length)
        return util.getUsefulTime(min)
        // if (min > 0) {
        //   let str = ''
        //   const days = Math.floor(min / (60 * 24))
        //   if (days) str += days + '天'
        //   const hour = Math.floor((min - days * 60 * 24) / 60)
        //   if (hour) str += hour + '时'
        //   return str || (15 + '分钟')
        // } else {
        //   return ''
        // }
      }
    }
  },
  methods: {
    clickHistoryNum (data) {
      // console.log('点击的历史记录车牌为', data)
      // console.log('正在展示的车牌为', this.plateNumber)
      const plateNum = data
      // 如果点击的车牌号和输入框的不一样 则填入  否则 什么也不做
      const inpNum = this.plateNumber.join('')
      if (plateNum === inpNum) {
        return
      }
      const nowArr = plateNum.split('')
      const bakArr = JSON.parse(JSON.stringify(this.plateNumberBak))
      for (let i = 0; i < nowArr.length; i++) {
        if (nowArr[i] !== '') {
          bakArr[i] = nowArr[i]
        }
      }
      this.plateNumber = bakArr
      this.setNumber(this.plateNumber)
    },
    notVhNumReceive () {
      console.log('准备想无牌车领券')
      this.getNotVhNum()
    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    setIndex (data) {
    },
    onInput (key) {
      this.para.pass = (this.para.pass + key).slice(0, 6)
    },
    onDelete () {
      this.para.pass = this.para.pass.slice(0, this.para.pass.length - 1)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    inputFocus (e) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = parseInt(e.target.dataset.index)
      } else {
        this.input_index = parseInt(e.target.dataset.index)
      }
    },
    updateHistoryVh () {
      if (this.plate.length === 7 || this.plate.length === 8) {
        const arrOld = this.historyNumList
        const arrNew = []
        arrNew.push(this.plate, ...arrOld)
        // 去重
        const qcArr = [...new Set(arrNew)]
        // console.log('qcArr'.qcArr)
        // 最大四个
        if (qcArr.length === 5) {
          qcArr.splice(qcArr.length - 1, 1)
        }
        this.historyNumList = qcArr
        // 保存vuex
        this.$store.commit(constant.keyQueryLogHistoryVuex, this.historyNumList)
      }
    },
    receive () {
      if (!this.pnulloutvisible) {
        for (let i = 0; i < this.plateNumber.length; i++) {
          if (i < 7) {
            if (!this.plateNumber[i]) {
              this.$toast('请输入车牌')
              return
            } else {
              this.plate = this.plateNumber.join('')
              this.updateHistoryVh()
            }
          }
        }
      }
      if (this.para.secret) {
        this.para.visible = true
        this.showKeyboard = false
      } else {
        this.canSend()
      }
    },
    canSend () {
      if (Number(this.para.category) === 2) {
        // 活动券
        if (Number(this.para.approveFlag) === 1) {
          Dialog.confirm({
            title: '提示',
            message: '该券为活动券，需要商户审核通过后才可以使用'
          })
            .then(async () => {
              // on confirm
              this.checkOnsite()
            })
            .catch(() => {
              // on cancel
            })
        } else {
          this.checkOnsite()
        }
      } else {
        // 非活动券
        this.checkOnsite()
      }
    },
    checkOnsite () {
      if (this.pnulloutvisible) {
        this.para.onsiteFlag = 1
        this.para.projectNumLimit = 1
        this.onsiteCanSend()
      } else {
        const category = Number(this.para.category)
        const onsiteFlag = Number(this.para.onsiteFlag)
        const projectNumLimit = Number(this.para.projectNumLimit)
        if (category === 2) {
          if (onsiteFlag === 1 || projectNumLimit === 1) {
            this.onsiteCanSend()
          } else {
            this.send()
          }
        } else {
          if (onsiteFlag === 1) {
            this.onsiteCanSend()
          } else {
            this.send()
          }
        }
      }
    },
    async onsiteCanSend () {
      const message = await this.queryOrder(2)
      if (message && message.code === 10000) {
        if (message.payablefee >= 0 || util.isTimeGreaterThanZero(message.parked_time)) {
          this.para.fee = message.payablefee // 金额 分
          this.para.parkedTime = util.timeStringToSeconds(message.parked_time) // 转化为秒数
          this.send()
        } else {
          Dialog.alert({
            title: '提示',
            message: '未在场内发现您的爱车。请确认此车辆是否已经入场。',
            confirmButtonText: '重新输入车牌号码'
          })
            .then(() => {

            })
          if (this.pnulloutvisible) {
            this.pnulloutvisible = false
          }
        }
      } else {
        Dialog.alert({
          title: '提示',
          message: message.msg || '查询异常，请稍后重试。',
          confirmButtonText: '重新输入车牌号码'
        })
          .then(() => {

          })
        if (this.pnulloutvisible) {
          this.pnulloutvisible = false
        }
      }
    },
    send () {
      var that = this
      if (this.para.true && this.para.secret !== this.para.pass) {
        this.$toast.fail('密码错误')
        return
      }
      try {
        const body = {
          v: '1.0',
          app_key: 'd7ca0abc-6b4b-44e4-bc71-7fd329ce2f7a',
          app_pass: '17f6e649-d26f-488f-b03b-5676aa3be362',
          sid: that.para.sid,
          rule_id: that.para.rule_id,
          coupon_id: that.para.coupon_id,
          vh_p_number: that.plate,
          value: that.para.value,
          secret: that.para.secret,
          all_free: that.para.all_free === 1 ? 1 : 0

        }
        if (Number(that.para.onsiteFlag) === 1 || Number(that.para.projectNumLimit) === 1) {
          if (that.para.fee || that.para.parkedTime) {
            body.car_onsite = 1
            body.parking_fee = that.para.fee
            body.parking_time = that.para.parkedTime
          }
        }

        axios({
          url: common.codeUrl + 'enterprise.coupon.delivery',
          method: 'POST',
          data: body,
          header: {
            'content-type': 'application/json'
          }
        }).then(res => {
          if (res.data.code && res.data.code === 10000) {
            const timeOrDay = parseInt(this.para.e_time_length)
            let days = Math.floor(timeOrDay / (60 * 24))
            if (days < 1) days = 0
            let hours = Math.floor((timeOrDay - days * (60 * 24)) / 60)
            if (hours < 1) hours = 0
            const min = Math.floor(timeOrDay % 60 / 60)
            let timeOrDayTxt = ''
            if (days) {
              timeOrDayTxt += days + '天'
            }
            if (hours) {
              timeOrDayTxt += hours + '小时'
            }
            if (min) {
              timeOrDayTxt += min + '分钟'
            }

            let tips = '领券成功.'
            if (Number(this.para.category) === 2) {
              // 活动券
              if (Number(this.para.approveFlag) === 1) {
                tips = '已提交领券申请，请耐心等待审核。审核通过后，'
              }
              const startDateTime = new Date(common.format(this.para.e_s_time + ' 00:00:00', 'yyyy-MM-dd hh:mm:ss'))
              const endDateTime = new Date(common.format(this.para.e_e_time + ' 23:59:59', 'yyyy-MM-dd hh:mm:ss'))
              const now = new Date()

              if (startDateTime.getFullYear() <= 1970 && endDateTime.getFullYear() === 2999) {
                if (this.para.allow_s_time === '00:00:00' && this.para.allow_e_time === '23:59:59') {
                  if (this.para.e_time_length === -1) {
                    tips += `请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} 离开车场前使用`
                  } else {
                    tips += `请在领券后 ${timeOrDayTxt} 内使用。`
                  }
                } else {
                  if (this.para.e_time_length === -1) {
                    tips += `请在领券后 ${this.para.allow_s_time + ' ~ ' + this.para.allow_e_time}离场使用。`
                  } else {
                    tips += `请在领券后 ${timeOrDayTxt}内 ${this.para.allow_s_time + ' ~ ' + this.para.allow_e_time}离场使用`
                  }
                }
              } else {
                if (startDateTime.getTime() > now.getTime()) {
                  tips = `优惠券未生效，不可领取。请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} 后领取，领券后{分钟}分钟内使用。`
                } else if (endDateTime.getTime() < now.getTime()) {
                  tips = '优惠券过期，不可领取。'
                } else {
                  if (startDateTime.getDate() === endDateTime.getDate()) {
                    if (this.para.allow_s_time === '00:00:00' && this.para.allow_e_time === '23:59:59') {
                      if (this.para.e_time_length === -1) {
                        tips += `请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} 离开车场前使用`
                      } else {
                        tips += `请在领券后 ${timeOrDayTxt} 内使用。`
                      }
                    } else {
                      if (this.para.e_time_length === -1) {
                        tips += `请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} 的 ${this.para.allow_s_time + ' ~ ' + this.para.allow_e_time}离场使用。`
                      } else {
                        tips += `请在 领券后 ${timeOrDayTxt} 内 ${this.para.allow_s_time + ' ~ ' + this.para.allow_e_time}离开车场前使用`
                      }
                    }
                  } else if (startDateTime.getDate() !== endDateTime.getDate()) {
                    if (this.para.allow_s_time === '00:00:00' && this.para.allow_e_time === '23:59:59') {
                      if (this.para.e_time_length === -1) {
                        tips += `请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} -  ${common.format(endDateTime, 'yyyy年MM月dd日')} 离开车场前使用`
                      } else {
                        tips += `请在领券后 ${timeOrDayTxt} 内使用。`
                      }
                    } else {
                      if (this.para.e_time_length === -1) {
                        tips += `请在 ${common.format(startDateTime, 'yyyy年MM月dd日')} ~ ${common.format(endDateTime, 'yyyy年MM月dd日')}，每天的${this.para.allow_s_time + ' ~ ' + this.para.allow_e_time}离开车场前使用`
                      } else {
                        tips += `请在领券后 ${timeOrDayTxt} 内使用。`
                      }
                    }
                  }
                }
              }
            } else {
              if (this.para.e_time_length === -1) { // 长期有效
                tips += '请尽快使用'
              } else {
                tips += `请在领券后 ${timeOrDayTxt} 内离场使用`
              }
            }
            // 如果是无牌车 领取完之后清除
            if (this.pnulloutvisible) {
              this.pnulloutvisible = false
            }
            this.successTips = tips
            that.sendSuccess = true
            this.para.visible = false
          } else {
            that.sendSuccess = false
            // 如果是无牌车 领取完之后清除
            if (this.pnulloutvisible) {
              Dialog.alert({
                title: '无牌车领券失败',
                message: res.data.msg
              })
                .then(() => {
                })
              this.pnulloutvisible = false
              return
            }
            // that.$toast(res.data.msg)
            Dialog.alert({
              title: '领券失败',
              message: res.data.msg
            })
              .then(() => {
              })
          }
        })
      } catch (e) {
        that.$toast(JSON.stringify(e))
      }
    },
    getCouponRuleInfo () {
      axios({
        url: common.codeUrl + 'enterprise.coupon.query_coupon_rule_info',
        method: 'POST',
        data: {
          v: '1.0',
          app_key: 'd7ca0abc-6b4b-44e4-bc71-7fd329ce2f7a',
          app_pass: '17f6e649-d26f-488f-b03b-5676aa3be362',
          sid: this.para.sid,
          rule_id: this.para.rule_id
        },
        header: {
          'content-type': 'application/json'
        }
      }).then(res => {
        if (res.data.code && res.data.code === 10000) {
          const data = res.data
          const startDateTime = new Date(common.format(data.e_s_time, 'yyyy-MM-dd hh:mm:ss'))
          const endDateTime = new Date(common.format(data.e_e_time, 'yyyy-MM-dd hh:mm:ss'))
          const now = new Date()
          if (data.e_s_time && startDateTime.getTime() > now.getTime()) {
            this.$toast(`优惠券未生效，不可领取。请在 ${common.format(data.e_s_time, 'yyyy年MM月dd日')}后领取`)
            // setTimeout( () => {
            //   this.close()
            // }, 1500)
            return
          }
          if (data.e_e_time && endDateTime.getTime() < now.getTime()) {
            this.$toast('优惠券已过期，不可领取。')
            // setTimeout( () => {
            //   this.close()
            // }, 1500)
            return
          }
          this.para.name = common.getProp('name')
          this.para.coupon_id = common.getProp('coupon_id')
          this.para.type = common.getProp('type')
          this.para.value = common.getProp('value')
          this.para.approveFlag = data.audit_enabled // 是否需要审核
          this.para.category = data.category // 1普通 2活动

          if (data.park_coupon_rules) {
            if (data.park_coupon_rules.check_car_onsite_enabled) {
              this.para.onsiteFlag = Number(data.park_coupon_rules.check_car_onsite_enabled) // 是否打开了在场车领取条件。1表示开启；0不开启
            }
            if (data.park_coupon_rules.activity_once_grant) {
              if (data.park_coupon_rules.activity_once_grant.enabled) {
                this.para.projectNumLimit = Number(data.park_coupon_rules.activity_once_grant.enabled) // 是否打开了活动券叠加限制。1表示开启；0不开启
              }
            }
          }
          if (data.secret) {
            this.para.secret = data.secret
          } else {
            this.para.secret = common.getProp('secret')
          }
          const sTime = decodeURIComponent(decodeURIComponent(common.getProp('sTime')))
          this.para.sTime = (sTime !== 'null' && sTime) ? sTime : ''
          const eTime = decodeURIComponent(decodeURIComponent(common.getProp('eTime')))
          this.para.eTime = (sTime !== 'null' && eTime) ? eTime : ''
          const permanent = common.getProp('permanent')
          this.para.permanent = (permanent === true || permanent === 'true') || false
          this.para.allow_s_time = common.getProp('allow_s_time') || ''
          this.para.allow_e_time = common.getProp('allow_e_time') || ''
          this.para.e_time_length = common.getProp('e_time_length') || ''
          // this.para.category = common.getProp('category') || ''

          if (Number(this.para.type) === 11 ||
            Number(this.para.type) === 12) {
            this.para.all_free = Number(common.getProp('all_free')) || 0
          } else {
            this.para.all_free = 0
          }
        } else {
          if (res.data.code && res.data.code === 99999) {
            this.$toast(res.data && res.data.msg)
          } else {
            this.$toast('查询出错')
          }
        }
      })
    },
    close () {
      doClose()
      function doClose () {
        var ua = window.navigator.userAgent.toLowerCase()// 获取判断用的对象
        if (ua.match(/MicroMessenger/i) === 'micromessenger') {
          if (window.WeixinJSBridge) {
            wxClose()
          } else {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', wxClose, false)
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', wxClose)
              document.attachEvent('onWeixinJSBridgeReady', wxClose)
            }
          }
          // eslint-disable-next-line no-inner-declarations
          function wxClose () {
            window.WeixinJSBridge.call('closeWindow')
          }
        } else if (ua.match(/AlipayClient/i) === 'alipayclient') {
          if (window.AlipayJSBridge) {
            zfbClose()
          } else {
            if (document.addEventListener) {
              document.addEventListener('AlipayJSBridgeReady', zfbClose, false)
            } else if (document.attachEvent) {
              document.attachEvent('AlipayJSBridgeReady', zfbClose)
            }
          }
          // eslint-disable-next-line no-inner-declarations
          function zfbClose () {
            window.AlipayJSBridge.call('closeWebview')
          }
        } else {
          window.close()
        }
      }
    },
    async init_mqtt () {
      var that = this
      const config = {
        onReconnect: that.subscribe
      }
      const client = await mqtt.getClient(config)
      if (client && client.connected) {
        return client
      } else {
        this.$toast.fail('NetWork err!')
      }
    },
    sendFeeHttpRequest (plateNumber) {
      const data = {
        sid: this.para.sid,
        plate_number: plateNumber,
        channel_path: -1,
        version: 'v2'
      }
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.queryPrice,
        method: 'POST',
        data: data,
        header: {
          'content-type': 'application/json' // 默认值
        }
      })
    },
    // sendInImgHttpRequest (plateNumber) {
    //   const data = {
    //     access_token: "CAD2815F-2B60-0001-3F23-15001A862980",
    //     app_key: "666a659e-244d-46b1-a8cc-3c5190c3823a",
    //     sid: this.para.sid,
    //     customer_id: '',
    //     plate_number: plateNumber,
    //     channel_path: -1,
    //     version: 'v2'
    //   }
    //   return axios({
    //     url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_inout_detail,
    //     method: 'POST',
    //     data: data,
    //     header: {
    //       'content-type': 'application/json' // 默认值
    //     }
    //   })
    // },
    subscribe: async function (action, plateNumber) {
      // 订阅
      const topic = `/ypt/u_${this.para.sid}/${encodeURIComponent(plateNumber)}`
      const options = {
        payload: {
          topic: action,
          plate_number: plateNumber,
          channel_path: -1
        }
      }
      await mqtt.subscribe(topic, options)
    },
    // ToDo 入场图片 等待后续处理
    // subscribeInImg: async function (action, plateNumber) {
    //   // 订阅
    //   const topic = `/ypt/u_${this.para.sid}/${'mq_query_inout_detail'}`
    //   const options = {
    //     payload: {
    //       topic: action,
    //       plate_number: plateNumber,
    //       channel_path: -1
    //     }
    //   }
    //   await mqtt.subscribe(topic, options)
    // },
    async queryOrder (type) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        if (Number(type) === 1) {
          // 只是查看在场信息 需要打开弹窗
          this.qryOnsiteWhenGetCoupon = true


        } else {
          this.qryOnsiteWhenGetCoupon = false
        }
        this.showKeyboard = false
        const plateNumber = this.plateNumber.join('')
        if (!plateNumber) {
          this.$toast('请输入车牌')
          return reject(new Error('请输入车牌'))
        }
        if (plateNumber.length !== 7 && plateNumber.length !== 8) {
          this.$toast('请输入车牌')
          return reject(new Error('请输入车牌'))
        }
        this.plate = plateNumber
        this.updateHistoryVh()
        this.$toast.loading({
          mask: true,
          message: '请稍候...',
          duration: 10000
        })
        const client = await this.init_mqtt()
        client.callback.fq = (topic, message) => {
          this.queryFQResult(null, message)
          resolve(message)
        }
        // client.callback.inImg = (topic, message) => {
        //   this.getInImgResult(null, message)
        //   resolve(message)
        // }
        await this.subscribe('sq', plateNumber)
        // await this.subscribeInImg('inImg', plateNumber)
        const res = await this.sendFeeHttpRequest(plateNumber)

        if (res && res.status >= 200 && res.status < 300) {
          const data = res.data
          if (data.code !== common.resCode.OK) {
            this.$toast.fail(common.resMSG[data.code] || '查询出错')
            reject(new Error(common.resMSG[data.code] || '查询出错'))
          } else {
          }
        }
      })
    },
    // getInImgResult(topic,message){
    // if (message.topic === 'inImg') {
    //     if (message.code === 10000 && message.vh_p_number) {
    //       this.$toast.clear()
    //       if(message.direction===1||message.direction==='1'){
    //       let reg = /^data:image\//
    //       let r = /^data:/
    //       let rd = `data:image/`
    //       this.inOutInfo.inImg = message.img && (reg.test(message.img) ? message.img : message.img.replace(r, rd))
    //       }
    //     } else {
    //       this.$toast(message.msg)
    //     }
    //   }
    // },

    queryFQResult (topic, message) {
      if (message.topic === 'fq') {
        if (message.code === 10000) {
          if (this.qryOnsiteWhenGetCoupon) {
            this.inOutInfo.show = true
          } else {
            this.inOutInfo.show = false
          }
          // if (message.msg === 'Success') {
          this.$toast.clear()

          this.inOutInfo.plateNumber = message.plate_number
          this.inOutInfo.inTime = common.format(message.start_time, 'yyyy-MM-dd hh:mm:ss')
          this.inOutInfo.parkTime = this.getParkTime(message)
          this.inOutInfo.totalFee = '￥' + (message.payablefee / 100).toFixed(2)
          // todo 展示图片

          // } else {
          //   this.$toast(message.msg)
          // }
        } else {
          this.$toast(message.msg)
        }
      }
    },
    getParkTime (message) {
      const total = new Date(message.end_time).getTime() - new Date(message.start_time).getTime()
      const hourStamp = 3600000
      const dayStamp = 24 * hourStamp
      const day = Math.floor(total / dayStamp)

      const hour = Math.floor((total - day * dayStamp) / hourStamp)
      const min = Math.floor((total - dayStamp * day - hour * hourStamp) / 60000)
      const second = Math.ceil((total % 60000) / 1000)
      let str = ''
      if (day) str += day + '天'
      if (hour) str += hour + '小时'
      if (min) str += min + '分'
      if (second) str += second + '秒'
      return str
    },
    handleClickOutside (event) {
      const keyboardElement = this.$refs.keyboard.$el
      if (keyboardElement && !keyboardElement.contains(event.target)) {
        if (this.showKeyboard) {
          this.showKeyboard = false
        }
      }
    },
    getNotVhNum () {
      const vhNotNum = localStorage.getItem('vh_p_number')

      if (vhNotNum && (vhNotNum.length === 7 || vhNotNum.length === 8)) {
        if (/^N/.test(vhNotNum)) {
          this.pnulloutvisible = true
          // Dialog.alert({
          //   title: '提示',
          //   message: '检测到无牌车，即将为您自动填充车牌。是否确认？'
          // })
          //   .then(() => {
          // on confirm 填充车牌
          if (vhNotNum.length === 8) {
            this.newFlag = true
          }
          this.input_index = vhNotNum.length - 1
          this.plate = vhNotNum
          this.plateNumber = vhNotNum.split('')
          this.updateHistoryVh()
          // })

          this.receive()
        }
      } else {
        this.$toast('未识别到无牌车')
      }
    }

  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    if (this.queryLogHistory && this.queryLogHistory.length) {
      this.historyNumList = this.queryLogHistory
    }
    this.para.sid = common.getProp('sid')
    this.para.rule_id = common.getProp('rule_id')

    this.getCouponRuleInfo()
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>
<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  font-size: 16px;
  background: rgba(51, 51, 51, 0.3);
}
.dialog .box {
  width: 298px;
  height: 274px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  text-align: center;
  margin: 38px auto 0;
  padding: 16px 27px 24px;
  box-sizing: border-box;
}
.dialogBtn {
  width: 244px;
  height: 38px;
  background: rgba(71, 151, 234, 1);
  border-radius: 4px;
  text-align: center;
  line-height: 38px;
  color: #fff;
  margin-top: 40px;
}
// /deep/ .van-cell__title{
//   text-align: center;
// }
// /deep/ .van-cell__value{
//   text-align: center;
// }
.section-2 {
  width: 3.51rem;
  background: #fff;
  margin: 0 auto;
}
.section-2 .step-1-title {
  font-size: 0.18rem;
  font-weight: bold;
  line-height: 0.7rem;
  color: #333;
}
.section-2 .vh-number-c {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /*justify-content: space-around;*/
  justify-content: space-between;
  /*position:relative;*/
}
.section-2 .vh-number-c .energe-icon {
  width: 0.16rem;
  height: 0.16rem;
  margin: 0.04rem auto auto auto;
}
.section-2 .vh-number-c .energe-text {
  position: absolute;
  bottom: 0.04rem;
  right: -0.04rem;
  font-size: 12px;
  color: #999;
  -webkit-transform: scale(0.8); /* Safari 和 Chrome */
  -webkit-transform-origin: 0 0; /* Safari 和 Chrome */
}
.section-2 .section {
  display: flex;
  width: 0.34rem;
  height: 0.44rem;
  border-radius: 0.02rem;
  background: rgba(255, 255, 255, 1);
  border: 0.01rem solid #ccc;
  /* border-radius:.05rem; */
  font-size: 0.16rem;
  color: #333;
  justify-content: center;
  align-items: center;
}
.section-2 .section-dashed {
  display: flex;
  width: 0.34rem;
  height: 0.44rem;
  border-radius: 0.02rem;
  // background: rgba(255, 255, 255, 1);
  border: 0.01rem dashed #ccc;
  /* border-radius:.05rem; */
  font-size: 0.16rem;
  color: #333;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.section-2 .active {
  border: 0.01rem solid #1989fa;
}
.section-2 .vh-input {
  text-align: center;
}
.section-2 .s-button {
  font-size: 0.16rem;
  margin-top: 0.78rem;
}
.section-2 .history {
  margin-top: 0.27rem;
  font-size: 0.13rem;
  display: flex;
  color: #999;
  /* align-items: center; */
  /* justify-content: space-between; */
}
.section-2 .type {
  // width: 2.54rem;
  width: 2.75rem;
  height: 0.82rem;
  background-image: url('../../assets/img/Group3.svg');
  display: flex;
  align-items: center;
  margin: 0.1rem auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.section-2 .type1 {
  background-image: url('../../assets/img/Group8.svg');
}
.section-2 .type2 {
  background-image: url('../../assets/img/Group2.svg');
}
.section-2 .type3 {
  background-image: url('../../assets/img/Group6.svg');
}
.section-2 .type .price {
  // font-size: 0.25rem;
  font-size: 0.2rem;
  font-weight: bold;
}
.section-2 .type .divider {
  height: 0.65rem;
  margin: auto 0.16rem;
  border-left: 2px dashed #fff;
}
.section-2 .type .info > div {
  font-size: 0.09rem;
  color: #666666;
}
.section-2 .type .info > div:first-child {
  font-size: 0.16rem;
  font-weight: bold;
  color: #333333;
}
.section-2 .btn {
  width: 2.44rem;
  height: 0.38rem;
  background: rgba(71, 151, 234, 1);
  border-radius: 4px;
  margin: 0.32rem auto 0;
  color: #fff;
  text-align: center;
  line-height: 0.38rem;
  font-size: 0.16rem;
}
.section-2 .success {
  width: 0.6rem;
  height: 0.41rem;
  background-image: url('../../assets/img/gou.png');
  margin: 0.46rem auto 0.14rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.section-2 .success1 {
  font-size: 0.16rem;
  text-align: center;
  font-weight: 500;
  color: #666;
}
.section-2 .success1 span {
  color: #333 !important;
}
.query-onsite-info {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  color: #5aaef5;
}
.plate_num_box {
  // height: 12.5rem;
  background-color: #fff;
  box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
  background-color: rgba(253, 254, 255, 1);
  border-radius: 0.08rem;
  margin-left: 0.01rem;
  display: flex;
  flex-direction: column;
  // padding: 0 0 1.0625rem;
  padding: 0.08rem;
  .plate_num_box_tit {
    font-size: 0.16rem;
    text-align: left;
    font-weight: bolder;
    margin-top: 0.16rem;
    margin-bottom: 0.16rem;
    margin-left: 0.1rem;
  }
  .plate_num_box_input {
    width: 3.24rem;
    margin-top: 0.11rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 0 0.08rem 0 0.08rem;
    .plate_wrap {
      width: 0.34rem;
      height: 0.44rem;
      background-color: rgba(233, 238, 255, 1);
      display: flex;
      align-items: center;
      text-align: center;
      .section-dashed {
        height: 0.44rem;
        border: 0.01rem solid #1989fa;
      }

      .plate_wrap_input {
        height: 0.44rem;
        width: 0.34rem;
        text-align: center;
        font-size: 0.16rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .new_vh {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.1rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.14rem;
          margin: 0.01rem 0 0 -0.01rem;
        }
      }
      .new_input {
        background-color: rgba(233, 255, 235, 1);
        img {
          width: 0.18rem;
          height: 0.17rem;
          margin: 0 0.06rem 0 0.05rem;
        }
      }
    }
  }
  .history {
    margin-top: 0.12rem;
    display: flex;
    justify-content: flex-start;
    // flex-wrap: wrap;
    align-items: center;
    .historyIcon {
      margin-left: 0.08rem;
      margin-bottom: 0.08rem;
      font-size: 0.1rem;
    }
    .historyItem {
      background-color: #f4f4f4;
      padding: 0.05rem 0.09rem;
      border-radius: 0.12rem;
      font-size: 0.1rem;
      color: #333;

      margin-right: 0.04rem;
      margin-left: 0.08rem;
      margin-bottom: 0.08rem;
    }
  }
  .query_btn {
    border-radius: 0.04rem;
    margin-top: 0.2rem;
  }
}

.notVhNumReceiveBox {
  display: flex;
  margin-top: 0.2rem;
  flex-direction: column;
  align-items: flex-start;
}

.notVhNumReceiveClass {
  color: #4797ea;
  font-size: 16px;
}
.notVhNumReceiveTip {
  color: #333;
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 0.1rem;
  text-indent: 2em;
}

.inImgTit {
  font-size: 14px;
  text-align: left;
}
.vanCellFirst::after {
  border: none;
}
</style>
